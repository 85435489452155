main.evento-giorno-sport img{
    max-width: 100%;
}

main.evento-giorno-sport {
    position: relative;
    background-position: center;
    background-size: cover;
    height: 100%;
    max-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1%;
    overflow: hidden;
    font-family: 'Helvetica Neue';
}

main.evento-giorno-sport .container{
    position: relative;
    top: -5%;
    max-width: 80%;
}

main.evento-giorno-sport .title{
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-size: clamp(1.25rem, 0.875rem + 1.875vw, 3.125rem);
    font-weight: 700;
    text-shadow: 1px 1px 2px black;
    margin: 0;
}

main.evento-giorno-sport .content{
    display: flex;
    align-items: center;
    justify-content: center;
}

main.evento-giorno-sport .score{
    position: relative;
    isolation: isolate;
}

main.evento-giorno-sport .score .score-container{
    display: flex;
    text-transform: uppercase;
}
main.evento-giorno-sport .score .score-container div{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(1.25rem, 0.875rem + 1.875vw, 3.125rem);
    font-weight: 700;
    padding: 5% 0;
    color: white;
    text-shadow: 1px 1px 2px black;
}

main.evento-giorno-sport .score > img{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

main.evento-giorno-sport .score .lightning{
    width: 20%;
}

main.evento-giorno-sport .logo{
    position: absolute;
    bottom: 10%;
    right: 10%;
    width: 10%;
}

main.evento-giorno-sport .logo img{
    max-width: 100%;
}