:root{
    --yellow: #FCB700;
}

main.evento-del-giorno-generico {
    position: relative;
    background-image: url("./images/background.png");
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Helvetica Neue';
}

main.evento-del-giorno-generico .container{
    max-width: 70%;
}

main.evento-del-giorno-generico .container *{
    text-align: center;
}

main.evento-del-giorno-generico .container h3{
    color: white;
    font-size: clamp(2.5rem, 2.375rem + 0.625vw, 3.125rem);
    line-height: 1;
    margin: 0 0 50px 0;
}
main.evento-del-giorno-generico .container h1{
    color: var(--yellow);
    font-size: clamp(6.25rem, 5rem + 6.25vw, 12.5rem);
    line-height: 1;
    margin: 0;
}