:root{
    --primary: #7bbfbe;
    --secondary: #258a83;
}

main.scommessa{
    font-family: 'Helvetica Neue';
    height: 100%;
    background-color: var(--primary);
    width: 100%;
}

main.scommessa header{
    padding: 10px 0;
}

main.scommessa header nav{
    background-color: var(--secondary);
}

main.scommessa header nav .container{
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

main.scommessa header .header-logo{
    display: flex;
    align-items: center;
    gap: 20px;
}

main.scommessa header .header-logo img{
    max-width: 150px;
}

main.scommessa header .header-logo h1{
    font-size: 40px;
    color: white;
    text-transform: uppercase;
}

main.scommessa header .header-date{
    color: white;
    font-size: 25px;
}

main.scommessa .content{
    max-width: 90%;
    margin: auto;
}

main.scommessa .content table{
    background-color: white;
    width: 100%;
    padding: .5rem;
    border-radius: 10px;
    overflow: hidden;
}

main.scommessa .content table tbody:before {
    content:"@";
    display:block;
    line-height:10px;
    text-indent:-99999px;
}

main.scommessa .content table thead span{
    background-color: var(--primary) !important;
    font-size: 30px !important;
    font-weight: 700;
}

main.scommessa .content table tr{
    display: flex;
    align-items: stretch;
}

main.scommessa .content tr td span{
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-size: 20px;
    height: 100%;
}

main.scommessa .content tr:nth-child(odd) td span{
    background-color: var(--secondary);
    color: white;
}


main.scommessa .content tr td:first-child{
    flex-grow: 1;
    padding-right: 5px;
}

main.scommessa .content tr td:first-child span{
    padding: 0px 4px;
}

main.scommessa .content tr td:not(:first-child){
    width: 140px;
    flex-shrink: 0;
    border-left: 1px solid var(--primary);
    padding: 0 5px;
}

main.scommessa .content tr td:not(:first-child) span{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
}

main.scommessa .content .bet{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    margin-top: 50px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
}

main.scommessa .content .bet > div{
    display: flex;
    align-items: center;
    gap: 20px;
}

main.scommessa .content .bet > div > label{
    display: block;
    padding: 5px;
    background-color: var(--secondary);
    color: white;
    border-radius: 5px;
}

main.scommessa .content .bet > div > input{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;

    border: none;
    outline: none;
    border-radius: 5px;
    padding: 5px;
    text-align: end;
}