:root{
    --primary-color: #09164c;
}

@font-face {
    font-family: 'PPPanagram';
    src: url("./fonts/PPPangramSans-Medium.otf");
    font-weight: 400;
}

@font-face {
    font-family: 'PPPanagram';
    src: url("./fonts/PPPangramSans-Bold.otf");
    font-weight: 700;
}

main.menu-del-giorno{
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-family: 'PPPanagram', sans-serif;
    height: 100%;
    color: var(--primary-color);
}

main.menu-del-giorno .main-inner{
    position: absolute;
    inset: 0;
    padding: max(2%, 30px);
    display: flex;
    flex-direction: column;
    background-color: white;
}

main.menu-del-giorno .main-container{
    flex-grow: 1;
    position: relative;
    border-radius: 10px;
    box-shadow: 
        0px 0px 0px 1px var(--primary-color),
        0px 0px 0px 8px white,
        0px 0px 0px 10px var(--primary-color);
    padding: max(1%, 15px);

    display: flex;
    flex-direction: column;

    max-height: calc(100% - max(2%, 30px));
}

main.menu-del-giorno header .logo{
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}

main.menu-del-giorno header .logo img{
    width: 30%;
    max-width: 170px; /* TODO: */
    height: auto;
}

main.menu-del-giorno header .header-content{
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 1vw;
    align-items: center;
}

main.menu-del-giorno header .header-content > .divider{
    height: 10px;
    border-top: 1px solid var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
}

main.menu-del-giorno header .header-content h2,
main.menu-del-giorno header .header-content h1{
    text-transform: uppercase;
    margin: 0;
    line-height: 0.5;
    text-align: center;
    font-weight: 400;
}
main.menu-del-giorno header .header-content h2{
    font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
}
main.menu-del-giorno header .header-content h1{
    font-size: clamp(2.5rem, 2.375rem + 0.625vw, 3.125rem);
}

main.menu-del-giorno .content-container{
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(500px, 100%), 1fr));
    gap: 5%;
    align-items: start;
    justify-content: center;
    text-align: center;
    overflow-y: auto;
    overflow-x: hidden;
}

main.menu-del-giorno .content-container section header{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

main.menu-del-giorno .content-container section p{
    word-break: break-all;
    white-space: normal;
}

main.menu-del-giorno .main-container .disclaimer{
    text-align: center;
    font-size: 14px;
}

