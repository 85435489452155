:root{
    --blue: #1E3662;
    --yellow: #FCB700;
}

html,
body{
    padding: 0;
    margin: 0;
    overflow: hidden;
}

main.riassunto-settimana{
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-template-rows: 50px auto;
    font-family: 'Helvetica Neue';
}

main.riassunto-settimana .title{
    grid-column: 1 / 8;
    grid-row: 1 / 2;
    padding: 5px;
    z-index: 100;
}

main.riassunto-settimana .title > h2{
    padding: 5px;
    margin: 0;
    border: 1px solid var(--blue);
    background-color: white;
    text-align: center;
    text-transform: uppercase;
    color: var(--blue);
}

main.riassunto-settimana .event{
    overflow: scroll;
    grid-row: 1 / 3;
    padding: 50px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

main.riassunto-settimana .event::-webkit-scrollbar{
    display: none;
}

main.riassunto-settimana .event:nth-child(2){
    grid-column-start: 1;
    grid-column-end: 2;
}
main.riassunto-settimana .event:nth-child(3){
    grid-column-start: 2;
    grid-column-end: 3;
}
main.riassunto-settimana .event:nth-child(4){
    grid-column-start: 3;
    grid-column-end: 4;
}
main.riassunto-settimana .event:nth-child(5){
    grid-column-start: 4;
    grid-column-end: 5;
}
main.riassunto-settimana .event:nth-child(6){
    grid-column-start: 5;
    grid-column-end: 6;
}
main.riassunto-settimana .event:nth-child(7){
    grid-column-start: 6;
    grid-column-end: 7;
}
main.riassunto-settimana .event:nth-child(8){
    grid-column-start: 7;
    grid-column-end: 8;
}

main.riassunto-settimana .event:nth-child(3n - 1){
    background-color: var(--yellow);
}

main.riassunto-settimana .event:nth-child(3n){
    background-color: white;
}

main.riassunto-settimana .event:nth-child(3n + 1){
    background-color: var(--blue);
}

main.riassunto-settimana .event .event-day{
    background-color: var(--blue);
    color: white;
    padding: 5px 0;
    text-align: center;
    font-weight: bolder;
    font-size: 24px;
}

main.riassunto-settimana .event .event-content-container{
    flex-grow: 1;
}

main.riassunto-settimana .event .event-content .event-hour{
    text-align: center;
    margin: 0;
    font-weight: 700;
    font-size: 20px;
}

main.riassunto-settimana .event:nth-child(3n - 1) .event-content .event-hour{
    color: white;
}
main.riassunto-settimana .event:nth-child(3n) .event-content .event-hour{
    color: var(--yellow);
}
main.riassunto-settimana .event:nth-child(3n + 1) .event-content .event-hour{
    color: var(--yellow);
}

main.riassunto-settimana .event .event-content .event-title{
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    font-weight: 900;
    font-size: 24px;
}

main.riassunto-settimana .event .event-content .event-description{
    text-align: center;
    margin: 10px 0;
    font-weight: 500;
    font-size: 18px;
}

main.riassunto-settimana .event .event-content .event-offer{
    text-align: center;
    margin: 10px 0;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 18px;
}

main.riassunto-settimana .event .event-content .event-disclaimer{
    display: block;
    text-align: center;
    margin: 30px 0;
    font-weight: 500;
    font-size: 14px;
}

main.riassunto-settimana .event:nth-child(3n - 1) .event-content .event-title,
main.riassunto-settimana .event:nth-child(3n - 1) .event-content .event-description,
main.riassunto-settimana .event:nth-child(3n - 1) .event-content .event-offer,
main.riassunto-settimana .event:nth-child(3n - 1) .event-content .event-disclaimer{
    color: var(--blue);
}

main.riassunto-settimana .event:nth-child(3n) .event-content .event-title,
main.riassunto-settimana .event:nth-child(3n) .event-content .event-description,
main.riassunto-settimana .event:nth-child(3n) .event-content .event-offer,
main.riassunto-settimana .event:nth-child(3n) .event-content .event-disclaimer{
    color: var(--blue);
}

main.riassunto-settimana .event:nth-child(3n + 1) .event-content .event-title,
main.riassunto-settimana .event:nth-child(3n + 1) .event-content .event-description,
main.riassunto-settimana .event:nth-child(3n + 1) .event-content .event-offer,
main.riassunto-settimana .event:nth-child(3n + 1) .event-content .event-disclaimer{
    color: white;
}

main.riassunto-settimana .event .event-banner{
    width: 100%
}

main.riassunto-settimana .event .event-banner img{
    max-width: 100%;
    object-fit: contain;
}
