:root{
    --yellow: #FCB700;
    --title: clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
}

div#html-evento-sportivo-generico{
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    font-family: 'Helvetica Neue';
}

#html-evento-sportivo-generico{
    position: relative;
    background-position: center;
    isolation: isolate;
}

#html-evento-sportivo-generico .ball-bg{
    position: absolute;
    width: 100%;
    bottom: 0;
}

#html-evento-sportivo-generico .logo{
    position: absolute;
    bottom: 50px;
    right: 50px;
}

#html-evento-sportivo-generico img{
    max-width: 100%;
}

#html-evento-sportivo-generico main{
    position: relative;
}

#html-evento-sportivo-generico main header{
    max-width: 50%;
    margin: auto;
    text-align: center;
}

#html-evento-sportivo-generico main header h1{
    font-size: var(--title);
    color: white;
    font-weight: 400;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
}

#html-evento-sportivo-generico main header h4{
    color: white;
    font-size: clamp(1rem, 0.7rem + 1.5vw, 2.5rem);
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
}

#html-evento-sportivo-generico main header .stars{
    display: block;
    margin: -20px auto;
}

#html-evento-sportivo-generico main .vs-container{
    padding: 3rem;
}

#html-evento-sportivo-generico main .vs-container .vs{
    position: relative;
    width: 100%;
}

#html-evento-sportivo-generico main .vs-container img{
    width: 100%;
}

#html-evento-sportivo-generico main .vs-container .vs-content{
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#html-evento-sportivo-generico main .vs-container .vs-content > div{
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#html-evento-sportivo-generico main .vs-container .vs-content h2{
    font-size: var(--title);
    color: white;
    font-weight: 400;
    text-align: center;
    margin-top: 30px;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
}

#html-evento-sportivo-generico .date{
    position: relative;
    isolation: isolate;
    width: 30%;
}

#html-evento-sportivo-generico .date > img{
    position: relative;
    z-index: -1;
}

#html-evento-sportivo-generico .date-content{
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: start;
    justify-content: center;
    color: white;
}

#html-evento-sportivo-generico .date-content .label{
    font-size: clamp(1.25rem, 1.125rem + 0.625vw, 1.875rem);
    line-height: 1;
}
#html-evento-sportivo-generico .date-content .hour{
    font-size: clamp(1.875rem, 0.375rem + 7.5vw, 9.375rem);
    line-height: 1;
}