body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
  }
  
  .fullscreen-video-container {
    width: 100vw;
    height: 100vh;
  }
  
  .fullscreen-video {
    width: 100%;
    height: 100%;
  }