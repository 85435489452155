:root{
    --yellow: #FCB700;
    --white: #ffffff;
    --blue: #1E3662;
    --title: clamp(1.25rem, 0.25rem + 5vw, 6.25rem);
    --centra-col-padding: 20px;
}

main.evento-sport-generico-2{
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    font-family: 'Helvetica Neue';
    background-color: var(--yellow);
    display: grid;
    grid-template-columns: 3fr 5fr 3fr;
}

main.evento-sport-generico-2 .left-col{
    background-position: center right;
    background-repeat: no-repeat;
}

main.evento-sport-generico-2 .central-col{
    background-position: center center;
    background-repeat: no-repeat;
    padding: var(--centra-col-padding);
    position: relative;
}

main.evento-sport-generico-2 .right-col{
    background-position: center left;
    background-repeat: no-repeat;
}

main.evento-sport-generico-2 .central-col .title{
    text-align: center;
    font-size: 40px;
}

main.evento-sport-generico-2 .central-col .banner{
    display: block;
    margin: auto;
    max-width: 100%;
}

main.evento-sport-generico-2 .central-col .offer{
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    background-color: var(--yellow);
    color: var(--blue);
    padding: 20px;
    text-align: center;
    font-weight: bold;
    width: fit-content;
}

main.evento-sport-generico-2 .central-col .offer span{
    font-size: clamp(0.9375rem, 0.75rem + 0.9375vw, 1.875rem);
    text-transform: uppercase;
}
main.evento-sport-generico-2 .central-col .offer h2{
    font-size: clamp(2.5rem, 2rem + 2.5vw, 5rem);
    margin: 0;
}

main.evento-sport-generico-2 .central-col .logo{
    position: absolute;
    bottom: var(--centra-col-padding);
    left: 50%;
    transform: translateX(-100%);
    max-width: 100%;
    width: 25%;
}

main.evento-sport-generico-2 .text-yellow{
    color: var(--yellow);
}
main.evento-sport-generico-2 .text-white{
    color: var(--white);
}
main.evento-sport-generico-2 .text-script{
    font-family: 'Script Pro';
}