:root{
    --primary-color: #09164c;
}

@font-face {
    font-family: 'PPPanagram';
    src: url("./fonts/PPPangramSans-Medium.otf");
    font-weight: 400;
}

@font-face {
    font-family: 'PPPanagram';
    src: url("./fonts/PPPangramSans-Bold.otf");
    font-weight: 700;
}

html,
body{
    padding: 0;
    margin: 0;
    overflow: hidden;
    height: 100%;
}

body{
    position: relative;
}

main.menu-cena{
    font-family: 'PPPanagram', sans-serif;
    color: var(--primary-color);
    background-color: white;
    position: absolute;
    inset: 0;
    padding: max(2%, 30px);
    display: flex;
    flex-direction: column;
}

main.menu-cena .main-container{
    flex-grow: 1;
    position: relative;
    border-radius: 10px;
    box-shadow: 
        0px 0px 0px 1px var(--primary-color),
        0px 0px 0px 8px white,
        0px 0px 0px 10px var(--primary-color);
    padding: max(1%, 15px);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    max-height: calc(100% - max(2%, 30px));
}

main.menu-cena .main-container .content{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

main.menu-cena header .logo{
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

main.menu-cena header .logo{
    width: 30%;
    max-width: 170px; /* TODO: */
    height: auto;
}

main.menu-cena header.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

main.menu-cena header.header p{
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    max-width: max(30%, 200px);
    text-transform: uppercase;
}

main.menu-cena header .header-content{
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 1vw;
    align-items: center;
    margin-bottom: 10px;
}

main.menu-cena header .header-content > .divider{
    height: 10px;
    border-top: 1px solid var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
}

main.menu-cena header .header-content h2,
main.menu-cena header .header-content h1{
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
    text-align: center;
    font-weight: 700;
}
main.menu-cena header .header-content h2{
    font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
}
main.menu-cena header .header-content h1{
    font-size: clamp(2.5rem, 2.375rem + 0.625vw, 3.125rem);
}

main.menu-cena .content-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10%;
    align-items: start;
    justify-content: center;
}

main.menu-cena .content-container section header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px
}

main.menu-cena .content-container section header h4{
    margin: 10px 0;
}

main.menu-cena .content-container p{
    margin: 0;
}

main.menu-cena .main-container .disclaimer{
    display: block;
    margin-top: 50px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
}

