:root{
    --primary: #7bbfbe;
    --secondary: #258a83;
}

main.evento-giornaliero {
    height: 100%;
    width: 100%;
    background-color: var(--primary);
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-family: 'Helvetica Neue';
}

main.evento-giornaliero header{
    padding: 10px 0;
}

main.evento-giornaliero header nav{
    background-color: var(--secondary);
}

main.evento-giornaliero header nav .container{
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

main.evento-giornaliero header .header-logo{
    display: flex;
    align-items: center;
    gap: 20px;
}

main.evento-giornaliero header .header-logo img{
    max-width: 150px;
}

main.evento-giornaliero header .header-logo h1{
    font-size: 40px;
    color: white;
    text-transform: uppercase;
}

main.evento-giornaliero header .header-date{
    color: white;
    font-size: 25px;
    text-transform: capitalize;
}

main.evento-giornaliero .content{
    max-width: 90%;
    margin: auto;
}

main.evento-giornaliero .content table{
    background-color: white;
    width: 100%;
    padding: .5rem;
    border-radius: 10px;
    overflow: hidden;
}

main.evento-giornaliero .content table tr{
    display: flex;
    align-items: stretch;
}

main.evento-giornaliero .content tr td span{
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-size: 20px;
    height: 100%;
}

main.evento-giornaliero .content tr:nth-child(odd) td span{
    background-color: var(--secondary);
    color: white;
}


main.evento-giornaliero .content tr td:first-child{
    border-right: 1px solid var(--primary);
    width: fit-content;
    padding-right: 5px;
}

main.evento-giornaliero .content tr td:first-child span{
    padding: 0px 4px;
}

main.evento-giornaliero .content tr td:last-child{
    flex-grow: 1;
    padding-left: 5px;
}

main.evento-giornaliero .content tr td:last-child span{
    padding: 0px 20px;
}