:root{
    --primary-color: #09164c;
    --blue-color: #1f3452;
    --yellow-color: #FCB700;
}

@font-face {
    font-family: 'PPPanagram';
    src: url("./fonts/PPPangramSans-Medium.otf");
    font-weight: 400;
}

@font-face {
    font-family: 'PPPanagram';
    src: url("./fonts/PPPangramSans-Bold.otf");
    font-weight: 700;
}

main.menu-pranzo {
    position: relative;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-family: 'PPPanagram', sans-serif;
    height: 100%;
    width: 100%;
    color: var(--primary-color);
    background-color: white;
}

main.menu-pranzo .main-inner{
    position: absolute;
    inset: 0;
    padding: max(2%, 30px);
    display: flex;
    flex-direction: column;
}

main.menu-pranzo .main-container{
    flex-grow: 1;
    position: relative;
    border-radius: 10px;
    box-shadow: 
        0px 0px 0px 1px var(--primary-color),
        0px 0px 0px 8px white,
        0px 0px 0px 10px var(--primary-color);
    padding: max(1%, 15px);

    display: flex;
    flex-direction: column;

    max-height: calc(100% - max(2%, 30px));
}

main.menu-pranzo header .logo{
    padding: 2% 0;
    display: flex;
    align-items: center;
    justify-content: start;
}

main.menu-pranzo header .logo img{
    width: 30%;
    max-width: 170px; /* TODO: */
    height: auto;
}

main.menu-pranzo .content-container{
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    overflow-y: auto;
    overflow-x: hidden;
    gap: max(2%, 30px);
}

main.menu-pranzo .content-container section .title{
    text-align: start;
    margin: 0;
}

main.menu-pranzo .content-container section.dotted .title{
    position: relative;
    z-index: 10;
    background-color: white;
    width: fit-content;
    padding: 0 10px;
}

main.menu-pranzo .content-container section .menu-item-container{
    padding: max(2%, 20px) max(1%, 10px);
    text-align: start;
}

main.menu-pranzo .content-container section .menu-item-container .menu-item-title,
main.menu-pranzo .content-container section .menu-item-container .menu-item-title *{
	display: inline;
}

main.menu-pranzo .content-container section .menu-item-container .menu-item{
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 10px;
	margin: 1em 0;
}

main.menu-pranzo .content-container section.normal .menu-item-container{
    border-top: 2px solid var(--primary-color);
}

main.menu-pranzo .content-container section.dotted .menu-item-container{
    position: relative;
    top: -10px;
    background-image: linear-gradient(to right, var(--primary-color) 50%, transparent 50%), linear-gradient(to right, var(--primary-color) 50%, transparent 50%), linear-gradient(to bottom, var(--primary-color) 50%, transparent 50%), linear-gradient(to bottom, var(--primary-color) 50%, transparent 50%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 22px 2px, 22px 2px, 2px 22px, 2px 22px;
}

main.menu-pranzo .content-container .banner{
    background-color: var(--blue-color);
    padding: 10px;
    border-radius: 10px;
}

main.menu-pranzo .content-container .banner > div{
    padding: 20px;
    color: var(--yellow-color);
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    background-image: linear-gradient(to right, var(--yellow-color) 50%, transparent 50%), linear-gradient(to right, var(--yellow-color) 50%, transparent 50%), linear-gradient(to bottom, var(--yellow-color) 50%, transparent 50%), linear-gradient(to bottom, var(--yellow-color) 50%, transparent 50%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 20px 2px, 20px 2px, 2px 20px, 2px 20px;
}

main.menu-pranzo .main-container .disclaimer{
    display: block;
    font-size: 14px;
    padding: max(2%, 20px) max(1%, 10px);
}

main.menu-pranzo .shrink-0 {
    flex-shrink: 0;
}