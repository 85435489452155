:root{
    --primary-color: #09164c;
    --blue-color: #1f3452;
    --yellow-color: #FCB700;
}

@font-face {
    font-family: 'PPPanagram';
    src: url("./fonts/PPPangramSans-Medium.otf");
    font-weight: 400;
}

@font-face {
    font-family: 'PPPanagram';
    src: url("./fonts/PPPangramSans-Bold.otf");
    font-weight: 700;
}

main.menu-bevande{
    position: relative;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-family: 'PPPanagram', sans-serif;
    height: 100%;
    width: 100%;
    color: var(--primary-color);
    background-color: white;
}

main.menu-bevande .main-inner{
    position: absolute;
    inset: 0;
    padding: max(2%, 30px);
    display: flex;
    flex-direction: column;
}

main.menu-bevande .main-container{
    position: relative;
    border-radius: 10px;
    box-shadow: 
        0px 0px 0px 1px var(--primary-color),
        0px 0px 0px 8px white,
        0px 0px 0px 10px var(--primary-color);
    padding: max(1%, 15px);

    display: flex;
    flex-direction: column;

    max-height: calc(100% - max(2%, 30px));
}

main.menu-bevande header .logo{
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: start;
}

main.menu-bevande header .logo img{
    width: 30%;
    max-width: 170px; /* TODO: */
    height: auto;
}

main.menu-bevande header .header-content{
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 1vw;
    align-items: center;
}

main.menu-bevande header .header-content > .divider{
    height: 10px;
    border-top: 1px solid var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
}

main.menu-bevande header .header-content h2,
main.menu-bevande header .header-content h1{
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
    text-align: center;
    font-weight: 400;
}
main.menu-bevande header .header-content h2{
    font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
}
main.menu-bevande header .header-content h1{
    font-size: clamp(2.5rem, 2.375rem + 0.625vw, 3.125rem);
    font-weight: 700;
}

main.menu-bevande .content-container{
    flex-grow: 1;
}

main.menu-bevande .content-container p{
    width: 100%;
    display: grid;
    grid-template-columns: 5fr 1fr 1fr;
    gap: 20px;
    align-items: center;
}

main.menu-bevande .content-container h4,
main.menu-bevande .content-container h3{
    margin: 0;
}

main.menu-bevande .content-container span,
main.menu-bevande .content-container h3{
    text-align: center;
}

main.menu-bevande footer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    column-gap: 5%;

    margin-top: 30px;
}

main.menu-bevande footer .content:first-child{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
main.menu-bevande footer .content:last-child{
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

main.menu-bevande footer .content h4{
    margin: 10px 0;
}

main.menu-bevande footer .banner{
    grid-column: 2 / 3;
    grid-row: 1 / 3;

    background-color: var(--blue-color);
    border-radius: 10px;
    padding: 10px;
    color: white;

    display: flex;
    align-items: stretch;
}

main.menu-bevande footer .banner .banner-content{
    width: 100%;
    background-image: linear-gradient(to right, var(--yellow-color) 50%, transparent 50%), linear-gradient(to right, var(--yellow-color) 50%, transparent 50%), linear-gradient(to bottom, var(--yellow-color) 50%, transparent 50%), linear-gradient(to bottom, var(--yellow-color) 50%, transparent 50%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 20px 2px, 20px 2px, 2px 20px, 2px 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

main.menu-bevande footer .banner .banner-content h3{
    text-transform: uppercase;
    text-align: center;
    margin: 0;
}

main.menu-bevande footer .banner .banner-content h4{
    margin: 10px;
}

main.menu-bevande footer .banner .banner-content section.yellow *{
    color: var(--yellow-color);
}

main.menu-bevande .container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}

main.menu-bevande .container span:first-of-type{
    text-align: center;
}

main.menu-bevande .container span:last-of-type{
    text-align: end;
}