:root{
    --blue: #1E3662;
    --yellow: #FCB700;
}

main.evento-settimanale {
    font-family: 'Helvetica Neue';
    background-size: cover;
    padding: 2rem;
    height: 100%;
    width: 100%;
    isolation: isolate
}

main.evento-settimanale img{
    max-width: 100%;
}

main.evento-settimanale .backdrop{
    position: absolute;
    background-color: white;
    top: 0;
    bottom: 0;
    left: -100px;
    width: 1000px;
    z-index: -1;
    transform: skewX(-10deg);
}

main.evento-settimanale #hour-info{
    color: var(--blue);
    background-color: var(--yellow);
    padding: 1rem;
    width: fit-content;
}

main.evento-settimanale #hour-info h2{
    margin: 0;
    font-weight: 900;
    text-transform: uppercase;
}

main.evento-settimanale #hour-info .hour-info-hour{
    font-style: italic;
}

main.evento-settimanale .banner{
    position: relative;
    width: fit-content;
}

main.evento-settimanale .banner .banner-img{
    position: relative;
    transform: translate(5%, -13%);
}

main.evento-settimanale .banner .banner-offer{
    position: absolute;
    width: 40%;
    top: 10%;
    left: -5%;
}